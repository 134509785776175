<template>
  <div class="shareDoc">
    <div
      v-if="modal === 'embed'"
      class="z-50 h-full fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
    >
      <div class="h-full fixed inset-0">
        <div class="fixed h-full inset-0 bg-gray-500 opacity-50"></div>
      </div>

      <div
        class="z-50 bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
          >
            <svg
              class="h-6 w-6 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Aprenda a integrar seu documento
            </h3>
            <div class="my-4">
              <p class="text-sm leading-5 text-gray-700 text-left">
                O código a seguir é um código que integra nossa página a sua!
                Você só precisa colocar o código do documento dentro da sua
                própria página de termos ou privacidade que nós entregamos no
                seu próprio sistema o documento.
              </p>
            </div>
            <div class="mt-2" v-if="embed.length > 0">
              <p class="text-sm text-gray-800 text-left">Link direto</p>
              <textarea
                class="form-input text-sm text-gray-900"
                rows="3"
                v-model="linkDireto"
              ></textarea>
            </div>
            <div class="mt-2" v-if="embed.length > 0">
              <p class="text-sm text-gray-800 text-left">Embed</p>

              <textarea
                class="form-input text-sm text-gray-900"
                rows="3"
                v-model="embed"
              ></textarea>
            </div>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button
              type="button"
              @click="handleCopyDocument(embed)"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Copiar
            </button>
          </span>
          <span
            class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
          >
            <button
              @click="modal = false"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Sair
            </button>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="modal === 'html'"
      class="z-50 h-full fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
    >
      <div class="h-full fixed inset-0">
        <div class="fixed h-full inset-0 bg-gray-500 opacity-50"></div>
      </div>

      <div
        class="z-50 bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-xl sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
          >
            <svg
              class="h-6 w-6 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Código em HTML
            </h3>
            <div class="my-4"></div>
            <div class="mt-2" v-if="embed.length > 0">
              <textarea
                class="form-input text-sm text-gray-900"
                rows="3"
                v-model="docHTML"
              ></textarea>
            </div>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button
              type="button"
              @click="handleCopyDocument(docHTML)"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Copiar
            </button>
          </span>
          <span
            class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
          >
            <button
              @click="modal = false"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Sair
            </button>
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="modal === 'text'"
      class="z-50 h-full fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
    >
      <div class="h-full fixed inset-0">
        <div class="fixed h-full inset-0 bg-gray-500 opacity-50"></div>
      </div>

      <div
        class="z-50 bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
          >
            <svg
              class="h-6 w-6 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Documento em texto
            </h3>
            <div class="my-4"></div>
            <div class="mt-2" v-if="embed.length > 0">
              <textarea
                class="form-input text-sm text-gray-900"
                rows="3"
                v-model="docText"
              ></textarea>
            </div>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button
              type="button"
              @click="handleCopyDocument(docText)"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Copiar
            </button>
          </span>
          <span
            class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
          >
            <button
              @click="modal = false"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Sair
            </button>
          </span>
        </div>
      </div>
    </div>
    <div
      style="max-width: 624px !important"
      v-if="selected_document !== 'others'"
      class="mx-auto bg-white space-y-2 grid grid-cols-2 pt-12"
      :style="{ background: settings.settingsExtra.backgroundColor }"
    >
      <div class="text-sm leading-5">
        <p
          @click="showModal('embed', document)"
          class="cursor-pointer group space-x-2 inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
        >
          <svg
            class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
              clip-rule="evenodd"
            />
          </svg>
          <span> Copiar link de visualização </span>
        </p>
      </div>
      <div class="flex flex-row text-sm leading-5">
        <p
          @click="showModal('html', document)"
          class="cursor-pointer group space-x-2 inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
        >
          <svg
            class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
              clip-rule="evenodd"
            />
          </svg>
          <span> Copiar texto em HTML </span>
        </p>
      </div>
      <div class="flex flex-row text-sm leading-5">
        <p
          @click="showModal('text', document)"
          class="cursor-pointer group space-x-2 inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
        >
          <svg
            class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
              clip-rule="evenodd"
            />
          </svg>
          <span> Copiar texto </span>
        </p>
      </div>
    </div>
    <v-style>
      .shareDoc { background:
      {{ settings.settingsExtra.backgroundColor }} } !important
    </v-style>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { functions } from '@/utils/functions';
import router from '@/router';

export default {
  name: 'CardDocument',
  computed: {
    ...mapState('document', ['documents']),
    ...mapState('auth', ['user']),
  },
  mixins: [functions],
  props: {
    selected_document: {},
    document: {},
    settings: {},
  },
  data() {
    return {
      modal: false,
      linkDireto: '',
      embed: '',
      docText: '',
      docHTML: '',
    };
  },
  methods: {
    ...mapActions('document', ['selectDocument', 'requestDownloadDocument']),
    ...mapActions('alert', ['success']),

    showModal(type) {
      this.selectDocument = this.document;
      this.modal = type;

      console.log(this.document);

      let serializedDocument = this.serializedNameDocument(
        this.document.document
      );

      let id_document = this.handleEncoderParam(this.document.id_document);
      let id_user = this.handleEncoderParam(this.user.id);

      // TODO: caso tenha mais docs, mudar aqui

      const id = '#' + this.document.document;

      this.docHTML = document.querySelector(id).outerHTML;
      this.docText = document.querySelector(id).outerText;

      const url = `${location.origin}/doc/embed/${serializedDocument}/${id_document}/${id_user}`;
      this.linkDireto = url;
      this.embed = `<embed type="text/html" src="${url}" width="100%" height="100%" style="min-height: 100vh" />`;
    },
    handleCopyDocument(value) {
      const elementAux = document.createElement('textarea');
      document.body.appendChild(elementAux);
      elementAux.value = value;
      elementAux.select();
      document.execCommand('copy');
      document.body.removeChild(elementAux);

      this.success({
        showType: 'toast',
        title: 'Código copiado com sucesso.',
      });
    },
    downloadDocument(document) {
      if (document.only_download) {
        this.requestDownloadDocument({
          document,
          id_user: this.user.id,
          router,
        });
      } else {
        let serializedDocument = this.serializedNameDocument(document.document);

        let id_user = this.user.id;
        let id_document = document.id_document;

        id_document = this.handleEncoderParam(id_document);
        id_user = this.handleEncoderParam(id_user);

        let url = `/doc/${serializedDocument}/${id_document}/${id_user}`;

        router.push({
          name: 'document',
          params: {
            nm_document: serializedDocument,
            id_document: id_document,
            id_user: id_user,
            download: true,
          },
        });
      }
    },
  },
};
</script>
