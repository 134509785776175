<template>
  <div class="flex flex-wrap grid grid-cols-2 gap-6 w-full p-12 m-0">
    <div
      v-for="document in documents"
      :key="document.id_document"
      class="w-full my-3 z-10"
      v-if="document.only_download"
    >
      <div
        v-if="modal"
        class="z-10 h-full fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
      >
        <div class="h-full fixed inset-0">
          <div class="fixed h-full inset-0 bg-gray-500 opacity-50"></div>
        </div>

        <div
          class="z-50 bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div
              class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
            >
              <svg
                class="h-6 w-6 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Aprenda a integrar seu documento
              </h3>
              <div class="my-4">
                <p class="text-sm leading-5 text-gray-700 text-left">
                  O código a seguir é um código que integra nossa página a sua!
                  Você só precisa colocar o código do documento dentro da sua
                  própria página de termos ou privacidade que nós entregamos no
                  seu próprio sistema o documento.
                </p>
              </div>
              <div class="mt-2" v-if="embed.length > 0">
                <textarea
                  class="form-input text-sm text-gray-900"
                  id="story"
                  rows="3"
                  v-model="embed"
                ></textarea>
              </div>
            </div>
          </div>
          <div
            class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
          >
            <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
              <button
                type="button"
                @click="handleCopyDocument(document)"
                class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Copiar
              </button>
            </span>
            <span
              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
            >
              <button
                @click="modal = false"
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Sair
              </button>
            </span>
          </div>
        </div>
      </div>

      <div
        class="col-span-4 z-0 w-full bg-white shadow-lg rounded-lg overflow-hidden"
      >
        <div class="px-4 py-3 bg-et-600 h-32 relative">
          <h1 class="text-white font-bold text-md uppercase">
            {{ document.nm_document }}
          </h1>
        </div>

        <div class="flex px-4 py-2 bg-white">
          <template v-if="!document.only_download">
            <button
              class="flex px-2 ml-2 py-1 bg-gray-200 text-xs text-gray-900 font-semibold rounded uppercase hover:bg-gray-400 focus:bg-gray-400"
              @click="() => showModal(document)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width="16"
                class="mr-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                />
              </svg>
              Copiar Código de Integração
            </button>
            <button
              class="flex px-2 ml-2 py-1 bg-gray-200 text-xs text-gray-900 font-semibold rounded uppercase hover:bg-gray-400 focus:bg-gray-400"
              @click="() => handleEditDocument(document)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                width="16"
                class="mr-1"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              Editar Informações
            </button>

            <button
              class="flex px-2 ml-2 py-1 bg-gray-200 text-xs text-gray-900 font-semibold rounded uppercase hover:bg-gray-400 focus:bg-gray-400"
              @click="() => handleSelectDocument(document)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                width="16"
                class="mr-1"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                />
              </svg>
              Visualizar
            </button>
          </template>

          <button
            v-if="verifyPossibilityOfDownload(document)"
            class="flex px-2 ml-2 py-1 bg-gray-200 text-xs text-gray-900 font-semibold rounded uppercase hover:bg-gray-400 focus:bg-gray-400"
            @click="() => downloadDocument(document)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              width="16"
              class="mr-1"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            Baixar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { functions } from '@/utils/functions';
import router from '@/router';

export default {
  name: 'CardDocument',
  computed: {
    ...mapState('document', ['documents']),
    ...mapState('auth', ['user']),
  },
  mixins: [functions],
  mounted() {},
  data() {
    return {
      modal: false,
      embed: '',
      home: '',
    };
  },
  created() {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
  methods: {
    ...mapActions('document', ['selectDocument', 'requestDownloadDocument']),
    ...mapActions('alert', ['success']),
    verifyPossibilityOfDownload(document) {
      return true;
    },
    handleSelectDocument(document) {
      let {
        id_document,
        nm_document,
        document: documentNameComponent,
      } = document;

      let serializedDocument = this.serializedNameDocument(
        documentNameComponent
      );

      let id_user = this.user.id;

      id_document = this.handleEncoderParam(id_document);
      id_user = this.handleEncoderParam(id_user);

      router.push(
        `/doc/${serializedDocument}/${id_document}/${id_user}?showEdit=true`
      );
    },
    handleEditDocument(document) {
      let {
        id_document,
        nm_document,
        document: documentNameComponent,
      } = document;

      let serializedDocument = this.serializedNameDocument(
        documentNameComponent
      );

      let id_user = this.user.id;

      id_document = this.handleEncoderParam(id_document);
      id_user = this.handleEncoderParam(id_user);

      router.push(
        `/doc/update/${serializedDocument}/${id_document}/${id_user}`
      );
    },
    showModal(Document) {
      this.selectDocument = Document;
      this.modal = true;

      let serializedDocument = this.serializedNameDocument(Document.document);

      let id_document = this.handleEncoderParam(Document.id_document);
      let id_user = this.handleEncoderParam(this.user.id);

      const url = `${location.origin}/doc/embed/${serializedDocument}/${id_document}/${id_user}`;
      this.embed = `<embed type="text/html" src="${url}" width="100%" height="100%" style="min-height: 100vh" />`;
    },
    handleCopyDocument(Document) {
      const elementAux = document.createElement('textarea');
      document.body.appendChild(elementAux);
      elementAux.value = this.embed;
      elementAux.select();
      document.execCommand('copy');
      document.body.removeChild(elementAux);

      this.success({
        showType: 'toast',
        title: 'Código copiado com sucesso.',
      });
    },
    downloadDocument(document) {
      if (document.only_download) {
        this.requestDownloadDocument({
          document,
          id_user: this.user.id,
          router,
        });
      } else {
        let serializedDocument = this.serializedNameDocument(document.document);

        let id_user = this.user.id;
        let id_document = document.id_document;

        id_document = this.handleEncoderParam(id_document);
        id_user = this.handleEncoderParam(id_user);

        let url = `/doc/${serializedDocument}/${id_document}/${id_user}`;

        router.push({
          name: 'document',
          params: {
            nm_document: serializedDocument,
            id_document: id_document,
            id_user: id_user,
            download: true,
          },
        });
      }
    },
  },
};
</script>
