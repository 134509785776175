<template>
  <div class="min-h-screen flex bg-gray-100 mt-8">
    <sidebar :page="'minha-conta'"></sidebar>
    <div class="flex flex-col w-0 flex-1">
      <main class="flex-1 relative z-0 pt-2 pb-6 focus:outline-none">
        <div class="max-w-7xl mx-auto">
          <div class="lg:flex">
            <div class="mt-5 flex ml-auto">
              <span class="hidden sm:block shadow-sm rounded-md">
                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    class="mr-2"
                    viewBox="0 0 24 24"
                    width="18"
                    stroke="#999"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Ajuda
                </button>
              </span>
            </div>
          </div>
        </div>

        <div
          class="mt-4 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white shadow-lg rounded py-0"
        >
          <div class="flex-1 min-w-0 pt-5">
            <h2
              class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate"
            >
              Minha Conta
            </h2>
          </div>

          <div v-if="loading">
            <span class="spinner" />
          </div>

          <div v-if="!loading && user">
            <div class="pt-0 my-8">
              <div class="w-full mx-auto">
                <div class="inputs w-full p-6 pt-0 mx-auto">
                  <h2 class="text-1xl text-gray-900">Configuração da conta</h2>
                  <form
                    class="mt-6 border-t border-gray-400 pt-4"
                    @submit.stop.prevent="onSubmit"
                  >
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-full px-3 mb-6">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-text-1"
                        >
                          E-mail
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-300 text-dark-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                          id="grid-text-1"
                          type="text"
                          placeholder="Enter email"
                          :value="form.email"
                          disabled
                        />
                      </div>
                      <div class="w-full md:w-full px-3 mb-6">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-text-1"
                        >
                          Senha
                        </label>
                        <input
                          class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                          id="grid-text-1"
                          type="password"
                          placeholder="Sua senha"
                          :state="
                            $v.form.password.$dirty
                              ? !$v.form.password.$error
                              : null
                          "
                          data-cy="login-password"
                          :class="{ 'border-10 border-red-500': errorMessages }"
                        />
                      </div>

                      <div
                        class="personal w-full border-t border-gray-400 pt-4"
                      >
                        <h2 class="text-1xl text-gray-900">
                          Informações Pessoais:
                        </h2>
                        <div class="flex items-center justify-between mt-4">
                          <div class="w-full md:w-1/2 px-3 mb-6">
                            <label
                              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            >
                              Nome
                            </label>
                            <input
                              class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                              type="text"
                              required
                              v-model="form.firstName"
                              placeholder="Nome"
                              :state="
                                $v.form.firstName.$dirty
                                  ? !$v.form.firstName.$error
                                  : null
                              "
                              data-cy="login-first-name"
                              :class="{
                                'border-10 border-red-500': errorMessages,
                              }"
                            />
                          </div>
                          <div class="w-full md:w-1/2 px-3 mb-6">
                            <label
                              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            >
                              Sobrenome
                            </label>
                            <input
                              class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                              type="text"
                              required
                              v-model="form.lastName"
                              placeholder="Nome"
                              :state="
                                $v.form.lastName.$dirty
                                  ? !$v.form.lastName.$error
                                  : null
                              "
                              data-cy="login-last-name"
                              :class="{
                                'border-10 border-red-500': errorMessages,
                              }"
                            />
                          </div>
                        </div>

                        <div class="flex justify-end mt-5">
                          <div class="w-1/4">
                            <input
                              type="submit"
                              :class="{
                                disabled: loading,
                                'bg-co-500': home === 'complianceOnline',
                                'bg-indigo-700': home === 'easyTermos',
                              }"
                              class="rounded disabled shadow-lg cursor-pointer text-white w-full py-3 mt-1"
                              value="Atualizar"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { email, required, minLength } from 'vuelidate/lib/validators';
import router from '@/router';
import sidebar from '../../components/Partials/Sidebar.vue';

export default {
  name: 'AccountUpdate',
  components: { sidebar },

  metaInfo() {
    return {
      title: 'Update my account',
      meta: [],
      home: '',
    };
  },
  mounted() {
    let url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    this.me({ router }).then(() => {});
  },
  data() {
    return {
      home: '',
      formLoaded: false,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
    };
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        minLength: minLength(6),
      },
    },
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('user', ['loading', 'user']),
  },
  methods: {
    ...mapActions('user', ['me', 'updateMe']),
    ...mapActions('alert', ['success', 'error']),
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (this.form.password && this.form.password.length < 6) {
        this.error({
          showType: 'toast',
          text: 'Sua senha precisa ter no mínimo 6 caracteres.',
        });
        return;
      }

      // Form submit logic
      this.updateMe({
        user: {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          password: this.form.password,
        },
        router,
      });
    },
  },
  watch: {
    user(_newValue, _oldValue) {
      this.form.firstName = this.user.first_name;
      this.form.lastName = this.user.last_name;
      this.form.email = this.user.email;
      this.formLoaded = true;
    },
  },
};
</script>
