<template>
  <div class="min-h-screen flex bg-gray-100 pt-14 relative">
    <div class="flex flex-col w-0 flex-1">
      <main class="flex-1 relative z-0 pt-2 pb-6 focus:outline-none">
        <div
          v-if="
            pageStatus !== 'none' &&
            pageStatus !== undefined &&
            pageStatus !== ''
          "
          class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50"
        >
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div
            class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
          >
            <div>
              <div
                class="absolute top-3 cursor-pointer text-sm right-5"
                @click="pageStatus = ''"
              >
                Fechar
              </div>
              <div v-if="pageStatus === 'success'">
                <p
                  class="mb-2 text-gray-500 uppercase tracking-wide font-semibold text-xs"
                >
                  Muito obrigado!
                </p>
                <h1
                  class="text-left text-gray-900 text-xl md:text-xl tracking-tight leading-tight mb-4"
                >
                  Seu pedido está sendo processado. Você pode confirmar
                  reabrindo essa página ou conferindo seu e-mail com a
                  confirmação de pagamento.
                </h1>
                <p class="text-left text-base text-gray-800 mb-5">
                  Caso tenha alguma dúvida, por favor entrar em contato.
                </p>
              </div>
              <div v-if="pageStatus === 'error'">
                <p
                  class="mb-2 text-gray-500 uppercase tracking-wide font-semibold text-xs"
                >
                  Erro :(
                </p>
                <h1
                  class="text-left text-gray-900 text-2xl md:text-2xl font-extrabold tracking-tight leading-tight mb-4"
                >
                  Tivemos um problema no pagamento do seu pedido. Por favor,
                  confirme o e-mail da plataforma de pagamento com as
                  informações sobre a assinatura.
                </h1>
                <p class="text-left text-base md:text-xl text-gray-800 mb-5">
                  Caso tenha alguma dúvida, por favor entrar em contato.
                </p>
                <a :href="`mailto:${contact}`" class="mb-8 btn btn-primary"
                  >Suporte</a
                >
              </div>
            </div>
          </div>
        </div>

        <DocumentView />

        <!-- /End replace -->
      </main>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import router from '@/router';
import sidebar from '../../components/Partials/Sidebar.vue';
import CardDocument from '@/components/CardDocument.vue';
import DocumentView from '@/components/Documents/DocumentView';

import { EMAIL_SUPORT_EASY, EMAIL_SUPORT_CO } from '@/utils/variables.json';

export default {
  name: 'Dashboard',
  components: {
    sidebar,
    CardDocument,
    DocumentView,
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      pageStatus: 'none',
      title: 'Dashboard',
      contact: '',
      EMAIL_SUPORT_EASY,
      EMAIL_SUPORT_CO,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('document', ['documents']),
  },
  methods: {
    ...mapActions('document', ['getAllDocuments', 'clearSelectedDocument']),
  },
  created() {},
  mounted() {
    if (this.$route.query.status !== null) {
      this.pageStatus = this.$route.query.status;
    } else {
      this.pageStatus = 'none';
    }

    this.getAllDocuments({ id_user: this.user.id });

    const url = document.URL;

    if (url.includes('localhost') || url.includes('compliance')) {
      this.contact = this.EMAIL_SUPORT_CO;
    } else if (url.includes('easy')) {
      this.contact = this.EMAIL_SUPORT_EASY;
    }
  },
};
</script>

<style>
.dash .vue-content-placeholders-text__line {
  width: 100% !important;
  height: 170px;
  border-radius: 10px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px !important;
  border-bottom: 38px solid white;
}
</style>
