<template>
  <div class="pt-20 bg-gray-100 rounded-lg pb-20">
    <div
      v-if="showModalTermosDeUso || showModalPlanoAvancado"
      class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-md sm:w-full sm:p-6"
      >
        <div>
          <div
            class="absolute top-3 cursor-pointer text-sm right-5"
            @click="
              showModalTermosDeUso = false;
              showModalPlanoAvancado = false;
            "
          >
            Fechar
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Você vai ser redirecionado para a tela de pagamento
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                Após a compra, você vai conseguir editar e baixar seus termos de
                uso, além de conseguir visualizar e baixar a política de
                privacidade.
              </p>
              <p
                class="text-sm leading-5 text-gray-500"
                v-if="showModalPlanoAvancado"
              >
                Além disso, você vai conseguir baixar os relatórios e guias,
                além de ter atualizações gratuitas no futuro.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <span class="flex gap-6 w-full rounded-md shadow-sm">
            <button
              type="button"
              @click="handleSelectPlan(1)"
              v-if="showModalPlanoAvancado === false"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-et-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-et-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Ir para o pagamento
            </button>
            <button
              @click="handleSelectPlan(5)"
              type="button"
              v-else-if="showModalPlanoAvancado === true"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-et-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-et-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Ir para o pagamento
            </button>
          </span>
        </div>
      </div>
    </div>
    <div style="max-width: 800px" class="mx-auto text-left mb-20">
      <h2 class="text-4xl font-bold text-gray-900 mb-2">
        <span v-if="selected_document === 'politicas-privacidade'"
        >Sua Política de Privacidade</span
        >
        <span v-else-if="selected_document === 'termos-de-uso'"
        >Seus termos de uso</span
        >
        <span v-else>Outros documentos</span>
      </h2>
      <p class="mb-6">
        Sua política é válida para uso em um domínio; sinta-se à vontade para
        editá-lo como quiser. Por favor, reveja suas políticas antes de
        adicionar ao seu site. Não nos responsabilizamos pelos termos. Por
        favor, não redistribua. Seus documentos não podem ser editados depois de
        finalizados, esse é um dos motivos para que o nosso produto continue
        acessível.
      </p>
    </div>
    <DocumentEditor
      v-if="selected_document !== 'others'"
      :settings="settings"
      :settings-default="settingsDefault"
    />
    <div
      class="doc relative bg-white shadow-2xl mx-auto rounded-3xl"
      style="max-width: 800px"
    >
      <nav
        class="bg-gray-200 rounded-t-xl flex flex-col sm:flex-row text-center text-sm"
      >
        <button
          :class="{
            'text-gray-600 bg-white':
              selected_document === 'politicas-privacidade',
          }"
          class="rounded-tl-2xl z-0 text-gray-800 w-full py-4 px-6 block focus:outline-none font-medium"
          @click="setDocument('politicas-privacidade')"
        >
          Política de Privacidade
        </button>
        <button
          :class="{
            'text-gray-600 bg-white':
              selected_document === 'politicas-cookies',
          }"
          class="z-0 text-gray-800 w-full py-4 px-6 block focus:outline-none font-medium flex items-center"
          @click="handleToolbar('politicas-cookies')"
        >
          <span class="mr-1" v-if="!hasAccessCookies">🔒</span>
          <svg v-else class="mr-1 w-5 h-5" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false"
               width="1em" height="1em"
               style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
               preserveAspectRatio="xMidYMid meet" viewBox="0 0 64 64">
            <path
              d="M36.9 22.7l2.5-18.6C37 3.5 34.6 2 32 2c-2.6 0-5 1.5-7.5 2.2c-2.5.6-5.3.5-7.5 1.8s-3.6 3.8-5.4 5.6C9.8 13.4 7.3 14.8 6 17c-1.3 2.2-1.2 5-1.9 7.5C3.5 27 2 29.4 2 32c0 2.6 1.5 5 2.2 7.5c.6 2.5.5 5.3 1.8 7.5s3.8 3.6 5.6 5.4c1.8 1.8 3.1 4.3 5.4 5.6c2.2 1.3 5 1.2 7.5 1.9c2.5.6 4.9 2.1 7.5 2.1c2.6 0 5-1.5 7.5-2.2c2.5-.7 5.3-.6 7.5-1.9c2.2-1.3 3.6-3.8 5.4-5.6c1.8-1.8 4.3-3.1 5.6-5.4c1.3-2.2 1.2-5 1.9-7.5c.6-2.4 2.1-4.8 2.1-7.4c0-2.6-2.1-8.1-2.1-8.1l-23-1.2"
              fill="#dda85f"/>
            <path
              d="M59.4 22.4c-1 .3-2.4.2-3.9-.4c-2.1-.8-3.4-2.5-3.8-4.5c-1 .3-3.4 0-5-1c-2.4-1.5-2.9-5.7-2.9-5.7c-2.7-.8-4.7-4-4.4-6.7c-2.2-.6-5-.5-7.4-.5c-2.4 0-4.6 1.4-6.8 2c-2.3.6-4.9.5-6.9 1.7s-3.3 3.5-4.9 5.1c-1.7 1.7-4 2.9-5.1 4.9c-1.2 2-1.1 4.6-1.7 6.9c-.6 2.2-2 4.4-2 6.8c0 2.4 1.4 4.6 2 6.8c.6 2.3.5 4.9 1.7 6.9s3.5 3.3 5.1 4.9c1.7 1.7 2.9 4 4.9 5.1c2 1.2 4.6 1.1 6.9 1.7c2.2.6 4.4 2 6.8 2c2.4 0 4.6-1.4 6.8-2c2.3-.6 4.9-.5 6.9-1.7s3.3-3.5 4.9-5.1c1.7-1.7 4-2.9 5.1-4.9c1.2-2 1.1-4.6 1.7-6.9c.6-2.2 3-4 3.3-6.4c.8-3.9-1.2-8.3-1.3-9"
              fill="#f2cb7d"/>
            <g fill="#dda85f">
              <path d="M50.1 10.8l-1.4 1.4l-1.3-1.4l1.3-1.3z"/>
              <path d="M55.8 17.8l-.6.7l-.7-.7l.7-.7z"/>
              <path d="M50.8 13.2l-.7.7l-.7-.7l.7-.7z"/>
              <path d="M44.6 7.1l-.7.7l-.7-.7l.7-.7z"/>
              <path d="M57.2 20.3l-.7.7l-.7-.7l.7-.7z"/>
              <path d="M57.8 17.8l-.7.7l-.7-.7l.7-.7z"/>
            </g>
            <path
              d="M11.8 20.6c-1 1.7.5 4.8 2.5 5.7c2.9 1.2 4.6 1.4 6.4-1.7c.6-1.1 1.4-4 1.1-4.7c-.4-1-2.1-3-3.2-3c-3.1.1-6.1 2.5-6.8 3.7"
              fill="#6d4934"/>
            <path d="M12.3 20.6c-.7 1.2 1.1 4.8 3.5 4.5c3.3-.4 3-7.2 1.6-7.2c-2.4 0-4.6 1.8-5.1 2.7"
                  fill="#a37f6a"/>
            <path
              d="M45.2 39.1c1.4-.4 2.4-2.9 1.8-4.4c-.9-2.3-1.8-3.3-4.4-2.6c-.9.3-3 1.4-3.2 1.9c-.3.8-.5 2.8.1 3.4c1.7 1.7 4.7 2 5.7 1.7"
              fill="#6d4934"/>
            <path d="M43.8 36.7c1.1-.3 2.8-3.7 1-3.9c-3.1-.5-5.5 1-5.2 2.7c.3 1.7 3.4 1.4 4.2 1.2"
                  fill="#a37f6a"/>
            <path
              d="M24.9 44.5c-.3-1.2-2.5-2.1-3.9-1.5c-2 .8-2.9 1.5-2.2 3.8c.2.8 1.2 2.6 1.7 2.7c.7.3 2.4.4 2.9-.1c1.5-1.4 1.7-4 1.5-4.9"
              fill="#6d4934"/>
            <path d="M23.2 43.6c-.2-.9-4.4.4-4 2c.8 2.7.8 3.1 1.6 3c1.5-.4 2.5-4.3 2.4-5"
                  fill="#a37f6a"/>
            <path
              d="M51.1 25.5c-1.2.3-2.1 2.5-1.5 3.9c.8 2 2.7 2.3 4.8 1.2c1.8-.9 1.9-4.1 1.4-4.7c-1.5-1.5-3.8-.6-4.7-.4"
              fill="#6d4934"/>
            <path d="M50.6 26.6c-.6.7-1.1 3.5.4 3.1c2.7-.8 4.6-3.5 3.4-3.9c-1.5-.5-3.1 0-3.8.8"
                  fill="#a37f6a"/>
            <path fill="#6d4934" d="M22.74 16.112l1.98-1.98l1.98 1.98l-1.98 1.98z"/>
            <g fill="#dda85f">
              <path d="M14.706 33.483l1.979-1.98l1.98 1.979l-1.979 1.98z"/>
              <path d="M34.698 44.811l1.98-1.98l1.98 1.98l-1.98 1.98z"/>
              <path d="M32.038 39.289l2.687-2.687l2.687 2.687l-2.687 2.687z"/>
              <path d="M24.696 9.827l2.687-2.687l2.687 2.687l-2.687 2.687z"/>
            </g>
            <g fill="#6d4934">
              <path d="M41.122 46.347l1.98-1.98l1.98 1.98l-1.98 1.98z"/>
              <path d="M49.076 35.215l1.98-1.98l1.98 1.98l-1.98 1.98z"/>
              <path d="M41.812 24.637l.99-.99l.99.99l-.99.99z"/>
              <path d="M13.726 38.266l.99-.99l.99.99l-.99.99z"/>
            </g>
          </svg>

          Política de Cookies
        </button>
        <button
          class="relative text-center text-gray-800 w-full py-4 px-6 focus:outline-none w-100"
          :class="{
            'text-gray-600 bg-white': selected_document === 'termos-de-uso',
          }"
          @click="handleToolbar('termos-de-uso')"
        >
          <div
            v-if="!hasAccessTerms"
            class="absolute w-3/4 -mt-20 top-0 right-0 mx-2"
          >
            <div
              class="opacity-0 hover:opacity-100 bg-gray-800 text-white text-sm rounded py-3 px-4 right-0 bottom-full"
            >
              Para acessar os termos de uso é necessário o pacote LGPD Completo
              <svg
                class="absolute text-gray-800 h-4 left-20 ml-3 top-full"
                x="0px"
                y="0px"
                viewBox="0 0 255 255"
                xml:space="preserve"
              >
                <polygon class="fill-current" points="0,0 127.5,127.5 255,0"/>
              </svg>
            </div>
          </div>
          <span v-if="!hasAccessTerms">🔒</span> Termos de Uso
        </button>
        <button
          class="relative text-center text-gray-800 w-full py-4 px-6 focus:outline-none w-100"
          :class="{
            'text-gray-600 bg-white':
              selected_document !== 'termos-de-uso' &&
              selected_document !== 'politicas-privacidade' &&
              selected_document !== 'politicas-cookies',
          }"
          @click="handleToolbar('others')"
        >
          <div
            v-if="!hasAccessReports"
            class="absolute w-3/4 -mt-20 top-0 right-0 mx-2"
          >
            <div
              class="opacity-0 hover:opacity-100 bg-gray-800 text-white text-sm rounded py-3 px-4 right-0 bottom-full"
            >
              Para acessar os relatórios e Guias é necessário o pacote LGPD
              Avançado
              <svg
                class="absolute text-gray-800 h-4 left-20 ml-3 top-full"
                x="0px"
                y="0px"
                viewBox="0 0 255 255"
                xml:space="preserve"
              >
                <polygon class="fill-current" points="0,0 127.5,127.5 255,0"/>
              </svg>
            </div>
          </div>

          <span v-if="!hasAccessReports">🔒</span> Relatórios e Guias
        </button>
      </nav>

      <section>
        <ShareDoc
          :document="document"
          :selected_document="selected_document"
          :settings="settings"
        />

        <div class="w-full bg-white" v-if="!hasAccessTerms">
          <div
            class="text-center mt-7 bg-blue-100 text-xs w-100 mx-auto py-3 border-et-500 rounded-md"
            style="max-width: 624px !important"
          >
            <b>Gostou da política gratuita?</b> Compre o pacote completo com 10%
            de desconto
            <div class="btn bg-et-500 ml-2 text-white text-xs btn-sm px-1 py-2">
              Cupom: LGPD10
            </div>
          </div>
        </div>

        <CardDocument
          v-if="documents.length > 0 && selected_document === 'others'"
        />
        <content-placeholders
          class="dash"
          v-else-if="selected_document === 'others'"
        >
          <content-placeholders-text :lines="4" class="mt-3"/>
        </content-placeholders>
        <transition name="fade">
          <div
            v-if="document.ds_json_props === null"
            class="my-16 pb-48 px-10 text-center mx-auto cursor-pointer"
            @click="() => handleEditDocument(document)"
          >
            <img
              src="/img/news.png"
              alt="#"
              class="mx-auto object-cover object-contain object-center"
              style="max-height: 400px"
            />
            <p class="text-2xl font-bold text-gray-800">
              Preencher {{ documentName }}
            </p>
          </div>
          <Matrix
            v-else-if="selected_document !== 'others'"
            style="padding-top: 0 !important"
            class="rounded-b-xl"
            :settings="settings"
            :style="{ background: settings.settingsExtra.backgroundColor }"
          />
        </transition>
      </section>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {functions} from '@/utils/functions';
import Matrix from '@/components/Matrix/Matrix';
import stripeService from '@/services/stripeService';
import CardDocument from '@/components/CardDocument';
import ShareDoc from '@/components/Documents/ShareDoc';
import router from '@/router';
import DocumentEditor from '@/components/DocumentEditor/DocumentEditor';
import documentService from '@/services/documentService';
import userService from "@/services/userService";
import {
  ID_ADVANCED_PLAN_EASY,
  ID_BASIC_PLAN_EASY,
  ID_COMPLETE_PLAN_EASY, ID_POLITICAS_COOKIES, ID_SPECIAL_PLAN_OLD_USERS
} from "@/utils/variables.json";

export default {
  name: 'DocumentView',
  components: {
    DocumentEditor,
    ShareDoc,
    CardDocument,
    Matrix,
  },
  created() {
    this.setDocument(this.selected_document);
    this.getActualPlanUser();
    this.getStylesDocument();
  },
  methods: {
    setDocument(document) {
      this.selected_document = document;

      if (this.documents.length < 1) {
        // TODO Gato para conseguir acessar o estado do vuex só quando houver os documentos
        setTimeout(() => {
          this.setDocument(document);
        }, 500);
      } else {
        this.$store.commit('document/clearDocument');

        let documentFound = this.findDocumentInDocuments(document);

        this.verifyAccessOnDocuments();

        this.$store.commit('document/setDocument', documentFound);
        this.getStylesDocument();
        this.$forceUpdate();
      }
    },
    findDocumentInDocuments(document) {
      return this.documents.find((doc) => {
        if (doc.document === document) {
          return doc;
        }
      });
    },
    handleToolbar(file) {
      if (file === 'termos-de-uso' && !this.hasAccessTerms) {
        this.showModalTermosDeUso = true;
      } else if (file === 'others' && !this.hasAccessReports) {
        this.showModalPlanoAvancado = true;
      } else if (file === 'politica-privacidade') {
        this.setDocument('politica-privacidade');
      } else if (file === 'politicas-cookies' && !this.hasAccessCookies){
        this.showModalTermosDeUso = true;
      }  else {
        this.setDocument(file);
      }
    },

    handleEditDocument() {
      let {
        id_document,
        nm_document,
        document: documentNameComponent,
      } = this.document;

      let serializedDocument = this.serializedNameDocument(
        documentNameComponent
      );

      let id_user = this.user.id;

      id_document = this.handleEncoderParam(id_document);
      id_user = this.handleEncoderParam(id_user);

      router.push(
        `/doc/update/${serializedDocument}/${id_document}/${id_user}`
      );
    },
    handleSelectPlan(planId) {
      stripeService.redirectToCheckoutEasy(planId);
    },
    verifyAccessOnDocuments() {
      this.documents.map((document) => {
        if (document.document === 'termos-de-uso') {
          if (document.has_access) {
            this.hasAccessTerms = true;
          }
        }

        if (document.document === 'relatorio-impacto.xlsm') {
          if (document.has_access) {
            this.hasAccessReports = true;
          }
        }
      });

      if (this.hasAccessPoliticasCookies) {
        this.hasAccessCookies = true;
      }
      this.$forceUpdate();
    },
    getStylesDocument() {
      this.settings = {...this.settingsDefault};

      documentService
      .getStylesFromDocument({
        id_user: this.user.id,
        id_document: this.document?.id_document,
      })
      .then((res) => {
        if (res.message.ds_json_styles) {
          this.settings = JSON.parse(res.message.ds_json_styles);

          this.$forceUpdate();
        }
      });
    },
    getActualPlanUser() {
      userService.actualPlan().then(res => {
        if (res && res.data) {
          this.actualPlan = res.data;
        }
      })
    }
  },
  mixins: [functions],
  data() {
    return {
      selected_document: 'politicas-privacidade',
      hasAccessTerms: false,
      showModalTermosDeUso: false,
      hasAccessReports: false,
      ID_ADVANCED_PLAN_EASY,
      ID_BASIC_PLAN_EASY,
      ID_COMPLETE_PLAN_EASY,
      ID_POLITICAS_COOKIES,
      ID_SPECIAL_PLAN_OLD_USERS,
      hasAccessCookies: false,
      actualPlan: {
        has_plan: false,
        plans: []
      },
      showModalPlanoAvancado: false,
      settings: {
        settingsHeading: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '18px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsBody: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '14px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsLink: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '14px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsExtra: {
          backgroundColor: '#fff',
          showPowered: true,
        },
      },
      settingsDefault: {
        settingsHeading: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '18px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsBody: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '14px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsLink: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '14px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsExtra: {
          backgroundColor: '#fff',
          showPowered: true,
        },
      },
    };
  },
  computed: {
    ...mapState('document', ['documents', 'document']),
    ...mapState('auth', ['user']),
    hasAccessPoliticasCookies() {
      const allowedPlans = [this.ID_COMPLETE_PLAN_EASY, this.ID_ADVANCED_PLAN_EASY, this.ID_SPECIAL_PLAN_OLD_USERS]
      return this.actualPlan.has_plan && (this.actualPlan.plans.includes(allowedPlans[0]) || this.actualPlan.plans.includes(allowedPlans[1]) || this.actualPlan.plans.includes(allowedPlans[2]));
    },
    documentName() {
      return this.document.nm_document
    }
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
