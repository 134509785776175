<template>
  <div class="min-h-screen flex mt-8">
    <sidebar :page="'minha-conta'"></sidebar>
    <div class="flex flex-col w-0 flex-1">
      <main class="flex-1 relative z-0 pt-2 pb-6 focus:outline-none">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div class="lg:flex lg:items-center lg:justify-between">
            <div class="mt-5 flex ml-auto">
              <span class="hidden sm:block shadow-sm rounded-md"> </span>
            </div>
          </div>
        </div>

        <div
          class="mt-4 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white shadow-sm rounded-lg"
        >
          <div class="flex-1 min-w-0 pt-5">
            <h2
              class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate"
            >
              Minha Conta
            </h2>
          </div>

          <div v-if="loading">
            <span class="spinner" />
          </div>

          <div v-if="!loading && user">
            <div class="pt-0 my-8">
              <div class="w-full mx-auto">
                <div class="w-full p-0 pt-0 mx-auto">
                  <div class="py-4 p">
                    <h1 class="text-md font-semibold text-gray-800">
                      {{ user.username }}
                    </h1>
                    <p class="py-2 text-md text-gray-700">{{ user.email }}</p>
                    <div class="flex items-center mt-4 text-gray-700">
                      <svg class="h-6 w-6 fill-current" viewBox="0 0 20 20">
                        <path
                          d="M15.573,11.624c0.568-0.478,0.947-1.219,0.947-2.019c0-1.37-1.108-2.569-2.371-2.569s-2.371,1.2-2.371,2.569c0,0.8,0.379,1.542,0.946,2.019c-0.253,0.089-0.496,0.2-0.728,0.332c-0.743-0.898-1.745-1.573-2.891-1.911c0.877-0.61,1.486-1.666,1.486-2.812c0-1.79-1.479-3.359-3.162-3.359S4.269,5.443,4.269,7.233c0,1.146,0.608,2.202,1.486,2.812c-2.454,0.725-4.252,2.998-4.252,5.685c0,0.218,0.178,0.396,0.395,0.396h16.203c0.218,0,0.396-0.178,0.396-0.396C18.497,13.831,17.273,12.216,15.573,11.624 M12.568,9.605c0-0.822,0.689-1.779,1.581-1.779s1.58,0.957,1.58,1.779s-0.688,1.779-1.58,1.779S12.568,10.427,12.568,9.605 M5.06,7.233c0-1.213,1.014-2.569,2.371-2.569c1.358,0,2.371,1.355,2.371,2.569S8.789,9.802,7.431,9.802C6.073,9.802,5.06,8.447,5.06,7.233 M2.309,15.335c0.202-2.649,2.423-4.742,5.122-4.742s4.921,2.093,5.122,4.742H2.309z M13.346,15.335c-0.067-0.997-0.382-1.928-0.882-2.732c0.502-0.271,1.075-0.429,1.686-0.429c1.828,0,3.338,1.385,3.535,3.161H13.346z"
                        ></path>
                      </svg>
                      <h1 class="px-2 text-sm">
                        {{ user.full_name }}
                      </h1>
                    </div>
                    <div class="flex items-center mt-4 text-gray-700">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 fill-current"
                        width="100"
                        height="100"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#FFFFFF"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="4" y="5" width="16" height="16" rx="2" />
                        <line x1="16" y1="3" x2="16" y2="7" />
                        <line x1="8" y1="3" x2="8" y2="7" />
                        <line x1="4" y1="11" x2="20" y2="11" />
                        <line x1="11" y1="15" x2="12" y2="15" />
                        <line x1="12" y1="15" x2="12" y2="18" />
                      </svg>
                      <h1 class="px-2 text-sm">
                        Último acesso:
                        <b>{{ user.last_login_at_formatted }}</b> no IP
                        <b>{{ user.last_login_ip }}</b>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-end mt-5 pb-5">
            <div class="w-1/2 md:w-1/4 mr-2">
              <button
                :class="{
                  disabled: loading,
                  'bg-co-500': home === 'complianceOnline',
                  'bg-indigo-700': home === 'easyTermos',
                }"
                class="button rounded disabled shadow-lg px-3 cursor-pointer text-white w-full py-3"
              >
                <a class="py-2 px-3" :href="customerPortal.url">
                  Informações do pagamento
                </a>
              </button>
            </div>
            <div class="w-1/2 md:w-1/4 mr-2">
              <router-link
                :to="'/account/update'"
                tag="button"
                :class="{
                  disabled: loading,
                  'bg-co-500': home === 'complianceOnline',
                  'bg-indigo-700': home === 'easyTermos',
                }"
                class="rounded disabled shadow-lg px-3 cursor-pointer text-white w-full py-3 relative"
              >
                Atualizar Informações
              </router-link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import sidebar from '../../components/Partials/Sidebar.vue';

import router from '@/router';

export default {
  name: 'Account',
  components: { sidebar },
  data() {
    return {
      home: '',
      portal: '',
    };
  },
  mounted() {
    this.me({ router });
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    let url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
  metaInfo() {
    return {
      title: 'My account',
      meta: [],
    };
  },
  created() {
    this.getLink();
  },
  computed: {
    ...mapState('user', ['loading', 'user', 'customerPortal']),
  },
  methods: {
    ...mapActions('user', ['me', 'getCustomerPortal']),
    async getLink() {
      await this.getCustomerPortal({ router });
    },
  },
};
</script>
